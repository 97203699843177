.popup-content {
  width: 90% !important;
}

@media (min-width: 420px) {
  .popup-content {
    max-width: 320px;
  }
}
@media (min-width: 1024px) {
  .popup-content {
    max-width: 530px;
  }
}
