.loader__container {
  width: 100px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: white;
}

.loader {
  width: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
  transform: translateX(-38px);
  animation: d1 0.5s infinite alternate linear;
  margin: auto;
}

@keyframes d1 {
  50% {
    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
  }
  100% {
    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
  }
}
