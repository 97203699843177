@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins:wght@400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  background-color: #f3f4f6;
  color: #001935;
}

img {
  max-width: 100%;
  height: auto;
}
